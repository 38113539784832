import React, { useState } from "react"
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import TextError from "../common/textError"
import axios from "axios"
import { successColor, HEROPrimary } from "../../static/styles/colors"
import { Link } from "gatsby"
import { motion } from "framer-motion"
import styled from "styled-components"

const validationSchema = Yup.object({
  Vorname: Yup.string().required("Vorname muss ausgefüllt sein!"),
  Nachname: Yup.string().required("Nachname muss ausgefüllt sein!"),
  Email: Yup.string()
    .email("E-Mail-Adresse ist nicht valide")
    .required("E-Mail-Adresse muss ausgefüllt sein!"),
  PLZ: Yup.string()
    .length(5, "Die PLZ sollte 5 Zeichen lang sein.")
    .matches(/^[0-9]{5}/, "Die PLZ entspricht nicht dem korrekten Format")
    .required("PLZ muss ausgefüllt sein!"),
  Telefonnummer: Yup.string(),
  Gewerk: Yup.string().required("Bitte wählen Sie ein Gewerk aus!"),
  Datenschutz: Yup.boolean().oneOf(
    [true],
    "Bitte die Datenschutzbestimmungen akzeptieren!"
  ),
})

const EventContact = ({ initialPropValues, isHERO }) => {
  const [serverState, setServerState] = useState()
  const [btnState, setBtnState] = useState(false) //prevent multiple clicks
  const fieldStyle = isHERO
    ? "fs-InterMedium form-control border-HERO-primary border-3 mb-2 "
    : "form-control border-success border-3 mb-2"

  const handleServerResponse = (ok, msg) => {
    setServerState({ ok, msg })
  }

  const onSubmit = (values, submitProps) => {
    setBtnState(true)
    axios
      .post("https://fachhandwerk360.de/webmailer/kontakt.php", values)
      .then(response => {
        submitProps.setSubmitting(false)
        submitProps.resetForm()
        handleServerResponse(
          true,
          "Sie haben sich erfolgreich für das Live Online Seminar angemeldet."
        )
        setBtnState(false)
      })
      .catch(error => {
        submitProps.setSubmitting(false)
        handleServerResponse(
          false,
          "Leider ist etwas schief gelaufen. Bitte melden Sie sich an info@fachhandwerk360.de"
        )
        throw new Error(
          "Failed to post form data. Check 'onSubmit()' function. Error: " +
            error
        )
      })
  }

  return (
    <Formik
      initialValues={initialPropValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {formik => {
        return (
          <motion.div
            initial={{ y: 50, opacity: 0 }}
            animate={{
              y: 0,
              opacity: 1,
            }}
            transition={{
              delay: 0.1,
              type: "spring",
              damping: 30,
              stiffness: 300,
            }}
            className="pt-0"
          >
            <Form>
              <div className="form-group mt-3">
                <Field
                  type="text"
                  id="HoneyPot"
                  name="HoneyPot"
                  className="form-control border-success border-3 d-none"
                />
                <div className="container p-0">
                  <div className="row">
                    <div className="col-md-6 p-2">
                      <label htmlFor="Vorname" className="text-light fs-5">
                        Vorname*
                      </label>
                      <Field
                        type="text"
                        id="Vorname"
                        name="Vorname"
                        placeholder="Ihr Vorname"
                        className={fieldStyle}
                      />
                      <ErrorMessage name="Vorname" component={TextError} />
                      <br />
                      <label htmlFor="Nachname" className="text-light fs-5">
                        Nachname*
                      </label>
                      <Field
                        type="text"
                        id="Nachname"
                        name="Nachname"
                        placeholder="Ihr Nachname"
                        className={fieldStyle}
                      />
                      <ErrorMessage name="Nachname" component={TextError} />
                    </div>
                    <div className="col-md-6 p-2">
                      <label htmlFor="PLZ" className="text-light fs-5">
                        PLZ
                      </label>
                      <Field
                        type="text"
                        id="PLZ"
                        name="PLZ"
                        placeholder="Ihre PLZ"
                        className={fieldStyle}
                      />
                      <ErrorMessage name="PLZ" component={TextError} />
                      <br />
                      <label
                        htmlFor="Telefonnummer"
                        className="text-light fs-5"
                      >
                        Telefonnummer
                      </label>
                      <Field
                        type="text"
                        id="Telefonnummer"
                        name="Telefonnummer"
                        placeholder="Ihre Telefonnummer"
                        className={fieldStyle}
                      />
                      <ErrorMessage
                        name="Telefonnummer"
                        component={TextError}
                      />
                    </div>
                    <div className="col-md-6 p-2">
                      <label htmlFor="Email" className="text-light fs-5">
                        E-Mail-Adresse*
                      </label>
                      <Field
                        type="email"
                        id="Email"
                        name="Email"
                        placeholder="Ihre E-Mail-Adresse"
                        className={fieldStyle}
                      />
                      <ErrorMessage name="Email" component={TextError} />
                    </div>
                    <div className="col-md-6 p-2">
                      <label htmlFor="Gewerk" className="text-light fs-5">
                        Gewerk*
                      </label>
                      <Field
                        as="select"
                        id="Gewerk"
                        name="Gewerk"
                        placeholder="Ihr Gewerk"
                        className={fieldStyle}
                      >
                        <option value={null}>-Bitte wählen-</option>
                        <option value="Elektroinstallation">
                          Elektroinstallation
                        </option>
                        <option value="Gas-, Wasser-, Heizungs- sowie Lüftungs- und Klimainstallation">
                          Gas-, Wasser-, Heizungs- sowie Lüftungs- und
                          Klimainstallation
                        </option>
                        <option value="Sonstige Bauinstallation">
                          Sonstige Bauinstallation
                        </option>
                        <option value="Anbringen von Stuckaturen, Gipserei und Verputzerei">
                          Anbringen von Stuckaturen, Gipserei und Verputzerei
                        </option>
                        <option value="Bautischlerei und -schlosserei">
                          Bautischlerei und -schlosserei
                        </option>
                        <option value="Fußboden-, Fliesen- und Plattenlegerei, Tapeziererei">
                          Fußboden-, Fliesen- und Plattenlegerei, Tapeziererei
                        </option>
                        <option value="Malerei und Glaserei">
                          Malerei und Glaserei
                        </option>
                        <option value="Dachdeckerei und Zimmerei">
                          Dachdeckerei und Zimmerei
                        </option>
                        <option value="Sonstige Spezialisierte Bautätigkeiten a n g">
                          Sonstige Spezialisierte Bautätigkeiten a n g
                        </option>
                      </Field>
                      <ErrorMessage name="Gewerk" component={TextError} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group mt-3">
                <p className="text-light">
                  Die mit * markierten Felder sind Pflichtfelder
                </p>
                <label
                  htmlFor="Datenschutz"
                  className="text-gray-500 font-bold"
                >
                  <Field
                    name="Datenschutz"
                    id="Datenschutz"
                    className="me-2 leading-tight"
                    type="checkbox"
                  />
                  <span className="text-light fs-5">
                    Ich habe die{" "}
                    <Link
                      to="/datenschutzerklaerung"
                      style={{ color: isHERO ? HEROPrimary : successColor }}
                    >
                      Datenschutzrichtlinien
                    </Link>{" "}
                    gelesen und akzeptiere diese.
                  </span>
                </label>
                <br />
                <ErrorMessage name="Datenschutz" component={TextError} />
                <br />
                <br />
                {isHERO ? (
                  <button
                    className="btn btn-HERO-primary fs-InterBold px-5 py-2 text-HERO-secondary rounded-pill mb-3"
                    type="submit"
                    disabled={btnState}
                  >
                    Jetzt kostenlos Anmelden
                  </button>
                ) : (
                  <button
                    className="shadow-inset-success-sm pt-2 pb-2 mt-4 rounded-0 d-block btn btn-secondary text-uppercase text-success border-success"
                    type="submit"
                    disabled={btnState}
                  >
                    Anmelden
                  </button>
                )}
              </div>
              {serverState && (
                <Modal className="modal d-block overflow-hidden">
                  <div className="modal-dialog modal-dialog-centered ">
                    <div className="modal-content">
                      <div className="d-flex flex-row-reverse">
                        <CloseButton
                          onClick={() => {
                            setServerState()
                          }}
                        >
                          X
                        </CloseButton>
                      </div>
                      <div className="fs-5 fs-InterMedium mt-3 p-3">
                        {serverState.msg}
                      </div>
                    </div>
                  </div>
                </Modal>
              )}
            </Form>
          </motion.div>
        )
      }}
    </Formik>
  )
}

const CloseButton = styled.button`
  position: absolute;
  z-index: 1;
  color: #000000;
  border: none;
  background-color: transparent;
  font-size: 25px;
  font-weight: bold;
  padding-right: 1rem;
`

const Modal = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
`

export default EventContact
