import React, { useState, useEffect } from "react"
import Layout from "../../../components/common/layout"
import { navigate } from "@reach/router"
import EventContact from "../../../components/online-seminar/eventContact"
import {
  BGLinearGradient,
  SeparatorExtended,
} from "../../../components/common/styledComponentsHelper"

export const Head = () => (
  <>
    <title>
      Live Online Seminar Bierdeckelkalkulation Anmeldung | Fachhandwerk360
    </title>
    <meta name="description" content="Alles was ein Handwerker braucht" />
    <meta
      name="keywords"
      content="Kalkulation, Beratung, Digitalisierung, Handwerk, Software, kaufmännischer
      Begleiter Bauhandwerk"
    />
  </>
)

const SignUp = ({ location }) => {
  const { state = {} } = location
  const { date, time, type } = state
  const [loaded, setLoaded] = useState(false)
  const propValues = {
    Vorname: "",
    Nachname: "",
    Email: "",
    PLZ: "",
    Telefonnummer: "",
    Gewerk: "",
    Datenschutz: false,
    HoneyPot: "",
    dataSendFrom: "eventSite",
    registrationLocation: "Website",
    registrationDate: date,
    eventTime: time,
    coopPartner: "-",
    type: type,
  }

  useEffect(() => {
    const scriptTag = document.createElement("script")

    scriptTag.src =
      "https://www.provenexpert.com/widget/landing_fachhandwerk360-dreier-herber-gbr.js?feedback=0&avatar=0&competence=0&style=black"
    scriptTag.addEventListener("load", () => setLoaded(true))
    document.body.appendChild(scriptTag)
  }, [])

  useEffect(() => {
    if (!loaded) return
  }, [loaded])

  if (date || time) {
    return (
      <Layout>
        <BGLinearGradient>
          <SeparatorExtended />
          <h2 className="text-uppercase fw-bold text-light text-center display-5">
            Jetzt zum Live Online Seminar am <br />
            <span className="text-success">{date}</span> um{" "}
            <span className="text-success">{time}</span> Uhr <br />
            <span className="text-success">Anmelden</span>
          </h2>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7">
                <EventContact initialPropValues={propValues} />
              </div>
            </div>
          </div>
          <SeparatorExtended />
        </BGLinearGradient>
      </Layout>
    )
  } else {
    navigate("/online-seminar/organic/datum")
  }
}

export default SignUp
